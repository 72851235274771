import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const PrivacyPage = () => (
	<Layout>
		<SEO title="Privacy policy." />
		<div className="container privacy-policy">
			<h1>Privacy policy.</h1>
			<PrivacyPageContent />
		</div>
	</Layout>
);

export const PrivacyPageContent = () => (
	<>
		<h2>Your privacy is important to us.</h2>
		<p>
			Privacy is something we take very seriously here at BeChampion (“BC”), and it is our goal to be open and
			transparent with what information we collect, and how we use it. This website, products, mobile
			applications, console or PC applications, and any services (collectively “Services”) linked by this Privacy
			Policy (“Policy”) are controlled by BC and its affiliates (“we”, “our”, or “we”).This Policy will explain
			what information we collect, why we collect it, and how we use it. Additionally, this Policy explains the
			choices we offer, including how to access and update your information. If you have any questions about this
			Policy, please contact us at: <a href="mailto:support@atlet.app">support@atlet.app</a> By using the
			Services, you are consenting to this Policy and the processing of your data, including your personal
			information, in the manner provided in this Policy. If you do not agree with these terms, please do not use
			the Services.
		</p>
		<h2>1. Information we collect</h2>
		<p>We collect information to provide better Services to our users and improve our business.</p>
		<p>
			<b>Information we get from your use of our Services.</b> We may collect information about the Services that
			you use and how you use them, like when you visit different parts of our site or applications and what
			selections you make on the Services. We may also automatically collect certain technical information such
			as:
		</p>

		<ul>
			<li>
				<b>Device information</b> We may collect device-specific information (such as your hardware model,
				operating system version, unique device identifiers, and mobile information if you use a mobile device
				to access the Services).
			</li>
			<li>
				<b>Personal Information</b> We collect the following personal information when you use our Services:
			</li>
			<li>Gamecenter and other playstore IDs</li>
			<li>
				<b>Log information</b> When you use our Services or view content provided by us, we may automatically
				collect and store certain information in server logs. This information may include:
			</li>
			<ul>
				<li>Details of how you used our service, such as your actions and user interface interactions</li>
				<li>Mobile related information if you access our Services using your mobile device.</li>
				<li>Internet protocol address.</li>
				<li>
					Device event information such as crashes, system activity, hardware settings, OS type, browser
					language, the date and time of your request and referral URL.
				</li>
				<li>Cookies that may uniquely identify your mobile device, or your account.</li>
				<li>Operating system, and other technical information</li>
			</ul>
		</ul>
		<h2>2. How we use information we collect</h2>
		<p>
			We use the information we collect from all of our Services to provide, maintain, protect and improve our
			Services, to develop new Services and offerings and to protect us and our users. We also use this
			information to improve your user experience.
		</p>
		<p>
			When you contact us, we may keep a record of your communication as well as the other information in this
			Privacy Policy to help solve any issues you might be facing. We may use your email address to inform you
			about our services, such as letting you know about upcoming changes or improvements.
		</p>
		<p>
			Please keep in mind that comments sections, forums, and other similar areas of our Services are public. Any
			information posted in those areas is viewable and usable by anyone that has access.
		</p>
		<p>
			We will ask for your consent before using information for a purpose other than those that are set out in
			this Privacy Policy.
		</p>
		<h2>3. Third Party Operators and Services</h2>
		<p>
			Our Privacy Policy does not apply to services offered by other companies or individuals, including products
			or sites that may be displayed to you on the Services. We also do not control the privacy policies and your
			privacy settings on third-party sites, including social networks.
		</p>
		<p>We use Third Party Operators to help offer you more tailored ads and better Services.</p>
		<p>For further information, please see the relevant privacy policies for each Third Party Operator:</p>
		<ul>
			<li>
				<a href="https://policies.google.com/privacy">Google Play Services</a>
			</li>
			<li>
				<a href="https://selectel.ru/about/documents/personal-data/">Selectel</a>
			</li>
		</ul>
		<h2>4. Choice</h2>
		<p>If we provide you with any mailings or other communication, you can always opt out of that communication.</p>
		<p>
			You have the right to remove your personal information from our databases. To do this, please contact
			customer support to delete your BC Account. If you have any problems or questions, please let us know by
			contacting us here <a href="mailto:support@atlet.app">support@atlet.app</a> and we will assist you with the
			process.
		</p>
		<h2>5. Accessing and updating your personal information</h2>
		<p>
			Whenever you use our Services, we aim to provide you with access to your personal information in your
			account. If that information is wrong, we strive to give you ways to update it quickly or to delete it
			unless we have to keep that information for legitimate business or legal purposes. When updating your
			personal information, we may ask you to verify your identity before we can act on your request.
		</p>
		<h2>6. Information we share</h2>
		<p>
			We do not share personal information with companies, outside organizations and individuals unless one of the
			following circumstances apply:
		</p>
		<p>
			<b>With your consent.</b> We will share personal information with companies, outside organizations or
			individuals if we have your consent to do so.
		</p>
		<p>
			<b>For external processing.</b> We provide personal information to our affiliates or other trusted
			businesses or persons to process it for us, based on our instructions and in compliance with our Privacy
			Policy and any other appropriate confidentiality and security measures.
		</p>
		<p>
			<b>For legal reasons.</b> We will share personal information with companies, outside organizations or
			individuals if we have a good-faith belief that access, use, preservation or disclosure of the information
			is reasonably necessary to meet any applicable law, regulation, legal process or enforceable governmental
			request, detect, prevent, or otherwise address fraud, security or technical issues or protect against harm
			to the rights, property or safety of our users or the public as required or permitted by law.
		</p>
		<p>
			<b>In case of a sale or asset transfer.</b> If we become involved in a merger, acquisition or other
			transaction involving the sale of some or all of our assets, user information, including personal
			information collected from you through your use of our Services, could be included in the transferred
			assets. Should such an event occur, we will use reasonable means to notify you, either through email and/or
			a prominent notice on the Services.
		</p>
		<p>
			<b>In aggregated form for business purposes.</b> We may share aggregated, non-personally identifiable
			information publicly and with our partners such as businesses we have a relationship with, advertisers or
			connected sites. For example, we may share information to show trends about the general use of our services.
		</p>
		<h2>7. Information security</h2>
		<p>
			We work hard to protect our users from unauthorized access to or unauthorized alteration, disclosure or
			destruction of information we hold however no website or storage system is entirely secure, and as such we
			can make no guarantee for the safety of your information stored in associated with the Services. You should
			protect the account information in your possession as well.
		</p>
		<h2>8. Enforcement</h2>
		<p>
			We regularly review our compliance with our Privacy Policy. When we receive formal written complaints, we
			will contact the person who made the complaint to follow up.
		</p>
		<h2>9. Children Under 13</h2>
		<p>
			Our Services are not directed toward children under 13 and we will not knowingly collect information for any
			child under the age of 13. If you are the parent of a child under the age of 13 and have a concern regarding
			your child’s information on our Services, please contact us at&nbsp;
			<a href="mailto:support@atlet.app">support@atlet.app</a>
		</p>
		<h2>11. Changes</h2>
		<p>
			Our Privacy Policy may change from time to time. We will post any Policy changes on this page and, if the
			changes are significant, we will provide a more prominent notice (including, for certain Services, email
			notification of privacy policy changes).
		</p>
	</>
);

export default PrivacyPage;
